import { memo, useState, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import useOnClickOutside from 'hooks/useOnClickOutside';
import styles from './Dropdown.module.scss';
import cx from 'classnames';

const Dropdown = ({ children, title = 'Type here', header = null, top = 40, right = 0, customStyling = '' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const el = useRef(null);

    
    useOnClickOutside(el, () => closeMenu());
    const closeMenu = () => {
        // console.log('about to close',e);

        // if (!el.current.contains(e.target)) {
            // console.log('closed',e);
            setIsOpen(false);
        // }
    }

    const toggleMenu = (e) => {
        // console.log('toggle menua',isOpen);
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);
    }
    return (
        <div ref={el} className={styles.ddWrapper}>
            <div onClick={toggleMenu} className={styles.ddHeader}>
                {header}
                {!header && (
                    <>
                        <span>{title}</span>
                        <span><FaChevronDown /></span>
                    </>
                )}
            </div>
            {isOpen && (
                <>
                    <div style={{ top, right }} className={cx(styles.ddList, customStyling)}>
                        <div>
                            {children}
                        </div>
                    </div>
                </>
            )}
        </div>
    );

};

export default memo(Dropdown);