import { useEffect, useReducer } from 'react';
import { fetchItems as fetchOfficeLocations } from 'shared/api/location';
import { fetchAllLocations } from 'shared/api/eventLocation';
import basicReducer from 'reducers/basicReducer';
// import { useParams } from 'react-router-dom';
// import {getItemById as getEventDetails} from 'shared/api/events';
// import Button from 'components/Button';
// import styles from './Map.module.scss';
// import Select from 'components/Form/Select';
import { mapApiKey } from 'shared/utils/common';
import mapService from './service';
import MapElement from './MapElement';

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${mapApiKey}`

const DisplayMapClass = props => {
	const [state, setState] = useReducer(basicReducer, {
		addNewLocationModal: false,
		showingInfoWindow: false,
		customLocation: '',
		currentLocation: {
			lat: '',
			lng: ''
		},
		officeLocations: [],
		pinLocations: [],
	});

	const onCloseAddLocationModal = () => {
		setState({ addNewLocationModal: false });
		loadEventLocations();
	}

	const getLocation = async () => {
		if (navigator && navigator.geolocation) {
			await navigator.geolocation.getCurrentPosition(async pos => {
				const coords = pos.coords;
				await setState({
					currentLocation: {
						lat: coords.latitude,
						lng: coords.longitude
					}
				});
			});
		} else {
			console.log("Geolocation is not supported by this browser.");
		}
	}

	useEffect(() => {
		// console.log('prams->', params.id);
		loadList();
		// await this.loadLocationCombo();
	}, []);

	const loadEventLocations = async () => {
		fetchAllLocations().then(out => {
			let pinLocations = [];
			if (out?.payload?.items?.records) {
				// console.log('loadEventLocations -> ',out?.payload?.items?.records);
				pinLocations = out?.payload?.items?.records?.filter?.(item => item?.Event?.status !== 'closed')?.map(item => ({
					id: item.id,
					name: item?.Event?.name || 'Event Name',
					icon: item?.icon || 'pin',
					eventId: item?.Event?.id,
					address: '',
					position: {
						lat: parseFloat(item.lat),
						lng: parseFloat(item.lng)
					}
				}));
			}
			// console.log('event locations ->', pinLocations);

			setState({ pinLocations });
		});
	}

	const loadList = async () => {
		await loadEventLocations();
		await fetchOfficeLocations().then(async list => {
			let formattedData = [];
			if (list?.payload?.items?.records) {
				formattedData = list?.payload?.items?.records?.map(item => ({
					name: item?.name,
					address1: item?.address1,
					address2: item?.address2,
					city: item?.city,
					state: item?.state,
					zip: item?.zip,
					country: item?.country,
					id: item?.id,
					fullAddress: `${item?.address1} ${item?.address2} ${item?.city} ${item?.state} ${item?.zip} ${item?.country}`,
					simpleAddress: `${item?.address1} ${item?.address2}, ${item?.city}, ${item?.state}, ${item?.country}`
				}));
			}

			let promisses = formattedData?.map(a => {
				return mapService.fetchAddressGeoCode(a?.name, a?.simpleAddress)
			});

			Promise.all(promisses).then(officeLocations => {
				setState({ officeLocations });
			}).catch(err => {
				console.log('err -> ', err);
			});

			// await this.loadLocationCombo(formattedData);
		});
		await getLocation();

	}

	const onDblClickMap = (clickEvent) => {
		
	}

	const setPinLocationIcon = (locationId,icon) => {

	}

	const removePin = id => {
		
	}

	const onOfficeClick = (marker, place) => {
	
	};

	const { pinLocations, officeLocations } = state;

	return (
		<>
			<div
				style={{
					position: "relative",
					height: "450px",
					// width: "90%"
					// height: "calc(100vh - 430px)"
				}}
			>
				<MapElement
					addNewLocationModal={state?.addNewLocationModal}
					onCloseAddLocationModal={onCloseAddLocationModal}
					googleMapURL={GOOGLE_MAP_URL}
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: `400px` }} />}
					mapElement={<div style={{ height: `100%` }} />}
					officeLocations={officeLocations}
					pinLocations={pinLocations}
					onOfficeClick={onOfficeClick}
					setPinLocationIcon={setPinLocationIcon}
					onDblClick={onDblClickMap}
					removePin={removePin}>
				</MapElement>
				
			</div>
		</>
	);
}

export default DisplayMapClass;