import {memo} from 'react';
import { useAuth } from 'components/ProvideAuth/hook';
import AccessDenied from 'components/AccessDenied';
import { useSelector } from 'react-redux';
import { hasPerm } from 'shared/utils/accessControl';

const PrivateRoute = ({ element,children = null,permission = null, ...rest }) => {
  // let state = useSelector(state => state);
  const userPermissions = useSelector((state:any) => state?.userPermissions);
  // let navigate = useNavigate();
  // let location = useLocation();
  let auth = useAuth();

  if (permission && !hasPerm(permission,userPermissions)) {
    return <AccessDenied />;
  }

  if(!auth?.user){
    return <AccessDenied />;
  }

  // console.log('auth->',auth);
  // else-> (<Navigate to="/login" state={{from:location.pathname}} />)
  return element;
};

export default memo(PrivateRoute);