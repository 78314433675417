import internalApi from 'shared/utils/internalApi';

export const fetchItems = ({ search = '', page = 1, limit = 50, individualSearchFields = [] } = {}) => {
  const queryParams = {
    search,
    page,
    limit,
    individualSearchFields
  };
  return internalApi.get('group/list', { params: queryParams })
};

export const getUsersInGroup = ({ search = '', page = 1, limit = 50, individualSearchFields = [], id = '' } = {}) => {
  const queryParams = {
    search,
    page,
    limit,
    individualSearchFields
  };
  return internalApi.get(`group/users/${id}`, { params: queryParams });
}


// export const exportCSV = checkedItems => () => {
//     internalApi.get('role/export', {
//         params: { checkedItems: checkedItems.join(',') },
//       }).then(res => {
//         exportCSVFile(res.payload.item, 'Roles');
//       });
//   };

export const getItemById = id => internalApi.get(`group/one/${id}`);
export const removeUserFromGroup = id => internalApi.post(`group/removeUserFromGroup/${id}`)
export const removeAllUsersFromGroup = id => internalApi.post(`group/removeAllUsersFromGroup/${id}`)
export const addUsersToGroup = (groupId, userIds) => internalApi.post(`group/addUsersToGroup/${groupId}`, { userIds })
export const saveItem = data => {
  return internalApi.post(
    data.id ? `group/update/${data.id}` : `group/create`,
    data,
  );
};

export const deleteItem = ids =>
  internalApi.post('group/delete-multiple', {
    ids,
  });