import moment from 'moment';

export function hasOnlyNumbers(str) {
	return /^\d+$/.test(str);
}

export const lowerFirstLetter = str => str.charAt(0).toLowerCase() + str.slice(1);
export const upperFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

export const cleanNewLine = (str) => {
	if (str) {
		return str.replace(/(\r\n|\n|\r)/gm, "")
	} else {
		return str;
	}
}

export const DateFormat = "MM/DD/YYYY hh:mm A";
// export const mapApiKey = "AIzaSyBRjr-TROIUORAe6HTkMC_NljYTj8OTZjY"; // Need to update key as we get
export const mapApiKey = process.env.REACT_APP_MAP_KEY; // Need to update key as we get

export const toNowISOString = () => {
	return new Date(Date.now()).toISOString();
}

export const camelPad = (str) => {
	return str
		.replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
		.replace(/([a-z\d])([A-Z])/g, '$1 $2')
		.replace(/([a-zA-Z])(\d)/g, '$1 $2')
		.replace(/^./, function (str) { return str.toUpperCase(); })
		.trim();
}

export const toCamelCase = (str) => {
	var retVal = '';

	retVal = str.trim()
		.replace(/[^A-Za-z]/g, ' ') /* clean up non-letter characters */
		.replace(/(.)/g, function (a, l) { return l.toLowerCase(); })
		.replace(/(\s.)/g, function (a, l) { return l.toUpperCase(); })
		.replace(/[^A-Za-z\u00C0-\u00ff]/g, '');

	return retVal
}

export const htmlToText = str => {
	if(str && typeof str === 'string'){
		return str.replace(/<[^>]+>/g, '');
	}else{
		return str;
	}
}

export const toSnakeCase = function (oStr) {
	var upperChars = oStr.match(/([A-Z])/g);
	if (!upperChars) {
		return oStr;
	}

	var str = oStr.toString();
	for (var i = 0, n = upperChars.length; i < n; i++) {
		str = str.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase());
	}

	if (str.slice(0, 1) === '-') {
		str = str.slice(1);
	}

	return str;
};

export const createFieldNameFromDisplayName = (name = '') => lowerFirstLetter(name)
	.replace(/[^\w ]+/g, '')
	.replace(/ +/g, '');

export const createDisplayNameFromFieldName = (name = '') => toSnakeCase(name).split('-').map(i => upperFirstLetter(i)).join(' ')

export const pad = (n, width, z) => {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function parseLookupValue(value = '') {
	//console.log("##########################");
	//console.log("about to parse ", value);
	//console.log("##########################");
	const _parse = v => {
		if (v) {
			let formattedValues = v;
			formattedValues = formattedValues.replace(/lookup@/, '');
			formattedValues = formattedValues.replace(/##/g, '"');
			formattedValues = formattedValues.replace(/---/g, ' ');
			formattedValues = formattedValues.replace(/___/g, ',');
			let obj = { v: v };
			try {
				let obj1 = JSON.parse(formattedValues);
				if (obj1) {
					obj = obj1;
				}
			} catch (e) {
				//console.error(`Error parsing value`,v);
			}
			//console.log("Returning =>",obj.v);
			return obj.v;
		} else {
			//console.log("Returning =>",v);

			return v;
		}
	};
	if (value && typeof value === 'string' && value.indexOf('lookup@') !== -1) {
		let result = value.split(',').map(v => _parse(v)).join(', ');
		//console.log("$Returning => ",result)
		return result;
	} else {
		//console.log("@Returning => ",value);
		return value;
	}
}

export function parseLookupKey(value = '') {
	//console.log("##########################");
	//console.log("about to parse ", value);
	//console.log("##########################");
	const _parse = v => {
		if (v) {
			let formattedValues = v;
			formattedValues = formattedValues.replace(/lookup@/, '');
			formattedValues = formattedValues.replace(/##/g, '"');
			formattedValues = formattedValues.replace(/---/g, ' ');
			formattedValues = formattedValues.replace(/___/g, ',');
			let obj = { v: v };
			try {
				let obj1 = JSON.parse(formattedValues);
				if (obj1) {
					obj = obj1;
				}
			} catch (e) {
				//console.error(`Error parsing value`,v);
			}
			//console.log("Returning =>",obj.v);
			return obj.id;
		} else {
			//console.log("Returning =>",v);

			return v;
		}
	};
	if (value && typeof value === 'string' && value.indexOf('lookup@') !== -1) {
		let result = value.split(',').map(v => _parse(v)).join(', ');
		//console.log("$Returning => ",result)
		return result;
	} else {
		//console.log("@Returning => ",value);
		return value;
	}
}

export function generateLookupID(id, value) {
	let obj = { id: id, v: value };
	let str = JSON.stringify(obj);
	str = str.replace(/"/g, '##');
	str = str.replace(/\s/g, '---');
	str = str.replace(/,/g, '___');
	return `lookup@${str}`;
}

export const calculateAverage = values => {
	if (values && values.length) {
		const avg = values.map(v => {
			let out = 0;
			if (typeof v !== 'undefined' && v !== null) {
				out = parseInt(v);
			}
			return out;
		}).reduce((prev, current) => {
			return prev + current;
		}, 0);

		return (avg / values.length).toFixed(1);
	} else {
		return 0;
	}
}

export const isSanitizedTxt = str => {
	let isValid = true;
	['<script>', '</script>'].forEach(forbidden => {
		if (str.toLowerCase().indexOf(forbidden) !== -1) {
			isValid = false;
		}
	});

	return isValid;
}

export const copyAttr = (attrFrom, data) => {
	let _form = null;
	if (data) {
		if (typeof data === 'object' && !Array.isArray(data)) {
			_form = {};
			for (let key of Object.keys(attrFrom)) {
				if (typeof data[key] !== 'undefined') {
					_form[key] = data[key];
				}
			}
		} else if (typeof data === 'object' && Array.isArray(data)) {
			_form = [];
			data.forEach(item => {
				let _item = {};
				for (let key of Object.keys(attrFrom)) {
					if (typeof item[key] !== 'undefined') {
						_item[key] = item[key];
					}
				}
				_form.push(_item);
			});
		}
	}
	return _form;
}

export const scrollToBottom = id => {
    var element = document.getElementById(id);
    element.scrollTop = element.scrollHeight - element.clientHeight;
 }

export const formatDate = (originalValue,format = 'M/D/YYYY hh:mm a' /* ll lll */) => {
    let valueToReturn = originalValue;
    let dt = originalValue ?
    hasOnlyNumbers(originalValue) ?
        moment.unix(originalValue).format(format)
        : moment(originalValue).format(format)
    : originalValue;
    if (dt !== 'Invalid date') {
        valueToReturn = dt;
    } else {
        valueToReturn = originalValue;
    }
    return valueToReturn;
}

export const diffDate = (dt) => {
    let now = moment(new Date()); //todays date
    let end = moment(dt); // another date
    let duration = moment.duration(now.diff(end)).asSeconds();

    // Calculate the number of days left
    let days = Math.floor(duration / 86400);
    // After deducting the days calculate the number of hours left
    let hours = Math.floor((duration - (days * 86400 ))/3600)
    // After days and hours , how many minutes are left
    let minutes = Math.floor((duration - (days * 86400 ) - (hours *3600 ))/60)
    // Finally how many seconds left after removing days, hours and minutes.
    let secs = Math.floor((duration - (days * 86400 ) - (hours *3600 ) - (minutes*60)))

    // var x = duration + "(" + days + " Days " + hours + " Hours " + minutes + " Minutes and " + secs + " Secondes " + ")";
    let diff = '0h 00s';
    if(days > 0){
        diff = `${days}d ${hours}h ${minutes}m`;
    }else if(hours > 0){
        diff = `${hours}h ${minutes}m ${secs}s`;
    }else{
        diff = `${minutes}m ${secs}s`;
    }

    return diff;

}

export const secondsToFormattedDuration = (seconds) => {
    // Calculate the number of days left
    let days = Math.floor(seconds / 86400);
    // After deducting the days calculate the number of hours left
    let hours = Math.floor((seconds - (days * 86400 ))/3600)
    // After days and hours , how many minutes are left
    let minutes = Math.floor((seconds - (days * 86400 ) - (hours *3600 ))/60)
    // Finally how many seconds left after removing days, hours and minutes.
    let secs = Math.floor((seconds - (days * 86400 ) - (hours *3600 ) - (minutes*60)))

    // var x = seconds + "(" + days + " Days " + hours + " Hours " + minutes + " Minutes and " + secs + " Secondes " + ")";
    let diff = '0h 00s';
    if(days > 0){
        diff = `${days}d ${hours}h ${minutes}m`;
    }else if(hours > 0){
        diff = `${hours}h ${minutes}m ${secs}s`;
    }else{
        diff = `${minutes}m ${secs}s`;
    }

	return diff;
}

export const diff2Dates = (current,dt, outputAs = 'formatted') => {
    let now = moment(current); //todays date
    let end = moment(dt); // another date
    let duration = moment.duration(now.diff(end)).asSeconds();
	
	if(outputAs === 'formatted'){
		return secondsToFormattedDuration(duration);
	}else{
		return duration;
	}

}