import { useEffect, useRef } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { MAP_SETTINGS } from './constants';
import { mapStyles } from './mapStyles';
import MyMarker from './MyMarker';

const {
    DEFAULT_ZOOM,
    DEFAULT_CENTER,
    DEFAULT_MAP_OPTIONS,
    MARKER_SIZE,
    PIXEL_OFFSET,
    DIRECTIONS_OPTIONS,
} = MAP_SETTINGS;

const MapElement = (props) => {
    const mapRef = useRef(null);

    const displayMarkers = (pinLocations,type = 'pin') => {
        if (pinLocations.length != 0) {
    
            return pinLocations.map((place, index) => {
                return <MyMarker 
                    mapRef={mapRef}
                    type={type}
                    id={place?.id?place.id:index} 
                    key={index} 
                    place={place}
                    eventId={place?.eventId}
                    setPinLocationIcon={props.setPinLocationIcon}
                    onClick={props.onOfficeClick}
                    removePin={props.removePin}
                />
            })
        }
    }	

    useEffect(() => {
        const bounds = new window.google.maps.LatLngBounds();
        if(props.officeLocations){
            props.officeLocations.forEach(({ position: { lat, lng } }) => {
                bounds.extend(new window.google.maps.LatLng(lat, lng))
            })
        }
        if(props.pinLocations){
            props.pinLocations.forEach(({ position: { lat, lng } }) => {
                bounds.extend(new window.google.maps.LatLng(lat, lng))
            })
        }
        mapRef.current.fitBounds(bounds);
    }, [props.pinLocations,props.officeLocations]);

    
    return (
        <>
        <GoogleMap
            ref={mapRef}
            onDblClick={props.onDblClick}
            defaultZoom={DEFAULT_ZOOM}
            defaultCenter={DEFAULT_CENTER}
            defaultOptions={{ ...DEFAULT_MAP_OPTIONS, styles: mapStyles }}
        >
            {displayMarkers(props.officeLocations,'office')}
            {displayMarkers(props.pinLocations,'pin')}
            
        </GoogleMap>
        						
        </>
    )
};

export default withScriptjs(withGoogleMap(MapElement));