import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import hixstory from './history';
// import { routerMiddleware } from 'react-router-redux';
// import rootReducer from '../reducers';

// const router = routerMiddleware(history);
const enhancer = applyMiddleware(thunk);

const initialState = {
  isSidebarOpen:true
};

const rootReducer = (state = initialState,action) => {
  switch(action?.type){
      case "SET":{
          if(typeof action?.payload === 'object'){
              return {...state,...action?.payload}
          }else{
              return state;
          }
      }
      default:
          return state;
  }
};

function configureStore(initialState) {
  return createStore(rootReducer, initialState, enhancer);
}

const store = configureStore();

export { store };
