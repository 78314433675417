import { useState, useContext } from 'react';
import authContext from './context';
import internalApi from 'shared/utils/internalApi';
import storage from 'shared/utils/storage';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as acl from 'shared/utils/accessControl';

interface IUser {
	id: string;
	firstName: string;
	lastName: string;
	companyId: string;
	email: string;
	avatar: string;
	isSuperAdmin: boolean;
	meta?: {
		twitter?: {
			listId?: string;
		}
	}
}

interface ICompanyMeta {
	zoom: {
		key: string;
		secret: string;
		accountEmail: string;
	},
	twitter: {
		listId: string;
	}
}

interface ICompany {
	alias: string;
	allowPublicRegistration: boolean;
	authType: string;
	id: string;
	isActive: string;
	lockoutPeriod: number;
	logo: string;
	meta: ICompanyMeta;
	name: string;
	numberOfCharactersAllowed: number;
	numberOfPriorPasswordsAllowed: number;
	passwordExpiration: number;
	twilioPhoneNo: string;
	twilioSID: string;
	twilioToken: string;
}

interface IUseAuth {
	company: ICompany;
	user: IUser;
	token: string;
	loadUserFromSession: Function;
	setCompany: Function;
	isForgotTokenValid: (token: string, cb: any) => void;
	[n: string]: any;
	// signin:Function;
	// signup:Function;
	// signupLead:Function;
	// reset:Function;
	// signout:Function;
}

const fakeAuth = {
	isAuthenticated: false,
	signin(cb) {
		fakeAuth.isAuthenticated = true;
		if (cb) {
			setTimeout(cb, 100); // fake async
		}
	},
	signup(cb) {
		fakeAuth.isAuthenticated = true;
		if (cb) {
			setTimeout(cb, 100); // fake async
		}
	},
	signout(cb) {
		fakeAuth.isAuthenticated = false;
		setTimeout(cb, 100);
	}
};

export const useAuth = (): IUseAuth => {
	return useContext(authContext);
}

export const useUserPartOfActionItems = (item) => {
	const userPermissions = useSelector((state:any) => state?.userPermissions);
    const auth = useAuth();
    let isAdmin = auth?.user?.isSuperAdmin || acl.hasPerm(acl.isAdmin(),userPermissions);
	let isUserPartOfActionItem = false;

	// IF this is not a confidential task them simply allow all users without going through checking
	if(!item?.isConfidential){
		return true;
	}
	
	let userId = auth?.user?.id;
	
	item?.actionUsers?.forEach?.(_user => {
		if(_user?.id === userId){
			isUserPartOfActionItem = true;
		}
	});

	item?.actionGroups?.forEach?.(_group => {
		_group?.GroupUsers?.forEach?.(_grpUser => {
			if(_grpUser?.id === userId){
				isUserPartOfActionItem = true;
			}
		});
	});

	// return isUserPartOfActionItem;
	return isAdmin || isUserPartOfActionItem;
}

export const useProvideAuth = () => {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);
	const [company, _setCompany] = useState(null);

	const setCompany = (data) => {
		// console.log('about to set company -> ', data);
		_setCompany(data);
		storage.set('company', data);
	}

	const signin = (form: any, cb) => {
		return internalApi.post({ server: 'auth/', path: 'authenticate' }, form).then(res => {
			// console.log('response',res?.payload?.item);
			storage.set('token', res?.payload?.item?.token);
			storage.set('user', res?.payload?.item?.user);
			storage.set('company', res?.payload?.item?.company);
			setToken(res?.payload?.item?.token);
			setUser(res?.payload?.item?.user);
			_setCompany(res?.payload?.item?.company);
			if (cb) {
				cb();
			}
			// setTimeout(() => {
			//     // history.push('/');
			// },3000);
		}).catch(err => {
			// console.log(err);
			// toast('Something went wrong');
		});

	};

	const signup = (form: any, cb) => {
		// console.log('form-> ',form);
		return internalApi.post({ server: 'auth/', path: 'register' }, form).then(res => {
			// console.log('response',res);
			toast.success(res.payload.message || 'Success!');
			// setUser("user");
			if (cb) {
				cb();
			}
			// setTimeout(() => {
			//     // history.push('/');
			// },3000);
		}).catch(err => {
			// console.log(err);
			// toast('Something went wrong');
		});
		// return fakeAuth.signup(() => {
		//   setUser("user");
		//   if(cb){
		//     cb();
		//   }
		// });
	};

	const signupLead = (form: any, cb) => {
		// console.log('form-> ',form);
		return internalApi.post({ server: 'auth/', path: 'registerLead' }, form).then(res => {
			// console.log('response',res);
			toast.success(res.payload.message || 'Success!');
			//setUser("user");
			if (cb) {
				cb();
			}
			// setTimeout(() => {
			//     // history.push('/');
			// },3000);
		}).catch(err => {
			// console.log(err);
			// toast('Something went wrong');
		});
		// return fakeAuth.signup(() => {
		//   setUser("user");
		//   if(cb){
		//     cb();
		//   }
		// });
	};

	const reset = (form: any, cb) => {
		return internalApi.post({ server: 'auth/', path: 'reset-password' }, form).then(res => {
			// console.log('response',res);
			toast.success(res.payload.message || 'Success!');
			// setUser("user");
			if (cb) {
				cb();
			}
			// setTimeout(() => {
			//     // history.push('/');
			// },3000);
		}).catch(err => {
			// console.log(err);
			// toast('Something went wrong');
		});

	};

	const isForgotTokenValid = (token: string, cb) => {
		return internalApi.post({ server: 'auth/', path: 'forgot-token-isvalid' }, { token }).then(res => {
			let isValid = res?.response?.isValid ?? false;
			if (cb) {
				cb(isValid);
			}
		}).catch(err => {
			cb(false);
		});

	};

	const forgotPassword = (form: any, cb) => {
		return internalApi.post({ server: 'auth/', path: 'forgot-password' }, form).then(res => {
			// console.log('response',res);
			toast.success(res.payload.message || 'Success!');
			// setUser("user");
			if (cb) {
				cb();
			}
			// setTimeout(() => {
			//     // history.push('/');
			// },3000);
		}).catch(err => {
			// console.log(err);
			// toast('Something went wrong');
		});

	};

	const signout = cb => {
		return fakeAuth.signout(() => {
			internalApi.post({ server: 'auth/', path: 'sign-out' })
			storage.remove('token');
			storage.remove('user');
			storage.remove('company');
			setUser(null);
			setToken(null);
			setCompany(null);
			if (cb) {
				cb();
			}
		});
	};

	const setAvatar = (avatar) => {
		let updatedUser = Object.assign({}, user, {
			avatar: avatar
		})
		storage.set('user', updatedUser);
		setUser(storage.get('user'));
	};

	const loadUserFromSession = () => {
		setUser(storage.get('user'));
		setToken(storage.get('token'));
		setCompany(storage.get('company'));
	}

	return {
		setCompany,
		token,
		company,
		user,
		signin,
		signup,
		signupLead,
		reset,
		isForgotTokenValid,
		forgotPassword,
		signout,
		loadUserFromSession,
		setAvatar
	};
}
