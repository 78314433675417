export default {
  set: (key, item) => {

    try {
      localStorage.setItem(key, JSON.stringify(item));
    } catch (e) {
      window.console.error(e);
    }
  },
  get: key => {

    let result = null;
    let value = localStorage.getItem(key);
    if(typeof value !== 'undefined' && value !== null){
      try {
        result = JSON.parse(value);
        if(result){
          if(typeof result === 'string' && result.substr(0,1) === '"' && result.substr(-1,1) === '"'){
            result = result.substr(1);
            result = result.substr(0,result.length-1);
          }
        }
      } catch (e) {
        window.console.error(e);
      }
  
    }
    return result;
  },
  remove: key => {

    try {
      localStorage.removeItem(key);
    } catch (e) {
      window.console.error(e);
    }
  },
};
