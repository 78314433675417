import {memo} from 'react';
// import PrivateRoute from 'components/PrivateRoute';
import RootLayout from 'views/layout/Root';
import AppRoutes from 'routes/App/AppRoutes';
import { Provider } from 'react-redux';
// import store from 'shared/utils/store';
import { store } from 'shared/store/store';
import IdleLogout from 'components/IdleLogout';
// import styles from './App.module.scss';

const App = props => {

    return (
        <Provider store={store}>
            <RootLayout>
                <AppRoutes />
            </RootLayout>
            <IdleLogout />
        </Provider>
    );

};

export default memo(App);