import { useState, useContext } from 'react';
import { nanoid }  from 'nanoid';
import socketContext from './context';
// import internalApi from 'shared/utils/internalApi';
// import storage from 'shared/utils/storage';
// import { toast } from 'react-toastify';
let listeners = [];

interface IUseSocket {
	subscribe(cb):string;
	unsubscribe(index):void;
	publish(eventName,cb):Function;
	on(eventName,cb):Function;
	off(eventName):Function;
	emit(eventName,params):Function;
	setSocket:Function;
}

export const useSocket = (): IUseSocket => {
	return useContext(socketContext);
}

export const useProvideSocket = (_originalSocket) => {
	const [socket, setSocket] = useState(_originalSocket);

	const on = (eventName,cb) => {
		if(socket){
			socket.on(eventName,cb);
		}
	}

	const subscribe = (fn):string => {
		let id = nanoid();
		listeners.push({id,fn});
		return id;
	}

	const unsubscribe = id => {
		let index = listeners.findIndex(l => l.id === id);
		listeners.splice(index,1);
	}
	
	const publish = (event,payload) => {
		if(listeners){
			listeners.forEach(listener => listener.fn(event,payload));
		}
	}

	const off = (eventName) => {
		if(socket){
			socket.off(eventName);
		}
	}
	
	const emit = (eventName,params) => {
		if(socket){
			socket.emit(eventName,params);
		}
	}

	return {
		subscribe,
		unsubscribe,
		publish,
		on,
		off,
		emit,
		// socket,
		
	};
}