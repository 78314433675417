import { useSelector,useDispatch } from 'react-redux';
import { setAction } from 'actions/set';

export const useSidebarVisible = () => {
    return useSelector((state:any) => state?.isSidebarOpen ?? true);
}

export const useSidebarToggle = () => {
    const dispatch = useDispatch();
    return (visible) => dispatch(setAction({isSidebarOpen:visible}));
}