import internalApi from 'shared/utils/internalApi';

export const fetchItems = ({ search = '', page = 1, limit = 50, individualSearchFields = [] } = {}) => {
  const queryParams = {
    search,
    page,
    limit,
    individualSearchFields
  };
  return internalApi.get('event/list', { params: queryParams })
};

// export const exportCSV = checkedItems => () => {
//     internalApi.get('role/export', {
//         params: { checkedItems: checkedItems.join(',') },
//       }).then(res => {
//         exportCSVFile(res.payload.item, 'Roles');
//       });
//   };

export const getItemById = id => internalApi.get(`event/one/${id}`);
export const saveItem = data => {
  let dataToPost = { ...data };
  if (data?.id) {
    delete dataToPost.id;
  }

  return internalApi.post(
    data.id ? `event/update/${data.id}` : `event/create`,
    dataToPost,
  );
};

export const changeEventStatus = id => {
  return internalApi.post(`event/changeEventStatus/${id}`);
}

export const groupCall = id => {
  return internalApi.post(`event/groupCall/${id}`);
}

export const zoomCall = id => {
  return internalApi.post(`event/zoomCall/${id}`);
}

export const deleteItem = ids =>
  internalApi.post('event/delete-multiple', {
    ids,
  });


export const getActionItems = eventId => internalApi.get(`event/listActionItems/${eventId}`);

export const deleteActionItem = actionId => internalApi.post(`event/deleteActionItem/${actionId}`);

export const createActionItem = (eventId, data) => {
  let params = { ...data };
  if (typeof params?.id !== 'undefined') {
    delete params.id;
  }

  return internalApi.post(`event/addActionItem/${eventId}`, params);
};

export const updateActionItem = data => {
  let params = { ...data };
  if (params?.id) {
    delete params.id;
  }
  return internalApi.post(`event/updateActionItem/${data.id}`, params);
};
export const saveUpdateEventItemOrder = (records, dragIndex, hoverIndex) => {
  let rec = records ? records?.map?.(r => ({ id: r.id })) : [];
  const queryParams = { records: rec, dragIndex, hoverIndex };
  return internalApi.post(`event/updateDisplayOrder`, queryParams);
}


export const exportEventActionsExcel = (eventId) => internalApi.get(`event/export/actionExcel`, { isFileDownload: true,params:{eventId} });

export const exportEventActionsCSV = (eventId) => internalApi.get(`event/export/actionCSV`,{params:{eventId}});