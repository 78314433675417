import internalApi from 'shared/utils/internalApi';

export const fetchItems = ({eventId} = {}) => {
    const queryParams = {
      };
    return internalApi.get(`event/location/list/${eventId}`, { params: queryParams })
};

export const fetchAllLocations = () => {
  return internalApi.get(`event/location/listAll/`);
};

// export const exportCSV = checkedItems => () => {
//     internalApi.get('role/export', {
//         params: { checkedItems: checkedItems.join(',') },
//       }).then(res => {
//         exportCSVFile(res.payload.item, 'Roles');
//       });
//   };

export const getItemById = id => internalApi.get(`event/location/one/${id}`);
export const saveItem = (eventId,data) => {
    return internalApi.post(
      data.id ? `event/location/update/${data.id}` : `event/location/create/${eventId}`,
      data,
    );
  };

export const deleteItems = ids =>
  internalApi.post('event/location/delete-multiple', {
    ids,
  });
  
export const deleteItem = id =>
  internalApi.post(`event/location/delete/${id}`
);
 