import {memo} from 'react';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.scss';
import { MdEventAvailable,MdFormatListBulleted,MdPeopleOutline,MdLockOutline, MdHome, MdFileUpload, MdSettings } from 'react-icons/md';
import { AiOutlineTeam,AiOutlineNotification,AiOutlineUser, AiFillThunderbolt } from 'react-icons/ai';
import { VscSymbolEvent } from 'react-icons/vsc';
import { GoLocation } from 'react-icons/go';
import { FiCreditCard } from 'react-icons/fi';
import { useSidebarVisible } from 'hooks/sidebar';
import { useAuth } from 'components/ProvideAuth/hook';
import { useSelector } from 'react-redux';
import * as acl from 'shared/utils/accessControl';
import { BsListCheck } from 'react-icons/bs';
import { IoDocumentOutline } from 'react-icons/io5';

const Sidebar = props => {
    const userPermissions = useSelector((state:any) => state?.userPermissions);

    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isSidebarOpen = useSidebarVisible();
// console.log('location.pathname->',location.pathname);
    return (
        <div style={{width:isSidebarOpen?250:90}} className={styles.root}>
            <ul className={styles.menu}>
                <li onClick={() => navigate('/home')} className={location.pathname.includes('/home')?styles.selected:''}><MdHome size={30} className={styles.menuIcon} /> {isSidebarOpen?"Home":""}</li>
                {acl.hasPerm(acl.canViewEvents(),userPermissions) && (
                    <li onClick={() => navigate('/events')} className={location.pathname.includes('/events')?styles.selected:''}><AiFillThunderbolt size={30} className={styles.menuIcon} /> {isSidebarOpen?"Events":""}</li>
                )}
                {acl.hasPerm(acl.canViewPlaybook(),userPermissions) && (
                    <li onClick={() => navigate('/playbooks')} className={location.pathname.includes('/playbooks')?styles.selected:''}><BsListCheck size={30} className={styles.menuIcon} /> {isSidebarOpen?"Playbooks":""}</li>
                    )}
                {/* <li onClick={() => navigate('/people')} className={location.pathname.includes('/people')?styles.selected:''}><MdPeopleOutline size={30} className={styles.menuIcon} />{isSidebarOpen?"People":""} </li> */}
                {acl.hasPerm(acl.canViewGroups(),userPermissions) && (
                    <li onClick={() => navigate('/groups')} className={location.pathname.includes('/groups')?styles.selected:''}><AiOutlineTeam size={30} className={styles.menuIcon} /> {isSidebarOpen?"Groups":""}</li>
                )}
                {acl.hasPerm(acl.canViewLocations(),userPermissions) && (
                    <li onClick={() => navigate('/locations')} className={location.pathname.includes('/locations')?styles.selected:''}><GoLocation size={30} className={styles.menuIcon} />{isSidebarOpen?"Locations":""} </li>
                )}
                {acl.hasPerm(acl.canViewDocumentManager(),userPermissions) && (
                    <li onClick={() => navigate('/document-manager')} className={location.pathname.includes('/document-manager')?styles.selected:''}><IoDocumentOutline size={30} className={styles.menuIcon} />{isSidebarOpen?"Documents":""} </li>
                )}
                {/* <li className={location.pathname === '/notifications'?styles.selected:''}><AiOutlineNotification size={30} className={styles.menuIcon} />Notifications</li> */}
            </ul>
            {acl.hasPerm(acl.canAdministrationMenu(),userPermissions) && (
                <ul className={styles.menu}>
                {isSidebarOpen?
                    <li className={location.pathname === '/administration'?styles.selected:''}><b>  Administration</b></li>:""
                }
                    {acl.hasPerm(acl.canViewUsers(),userPermissions) && (
                        <li onClick={() => navigate('/admin/users')} className={location.pathname.includes('/admin/users')?styles.selected:''}><AiOutlineUser size={30} className={styles.menuIcon} />{isSidebarOpen?"Users":""} </li>
                    )}
                    {acl.hasPerm(acl.canViewRoles(),userPermissions) && (
                        <li onClick={() => navigate('/admin/roles')} className={location.pathname.includes('/admin/roles')?styles.selected:''}><MdLockOutline size={30} className={styles.menuIcon} />{isSidebarOpen?"Roles":""}   </li>
                    )}
                    <li onClick={() => navigate('/admin/company/settings')} className={location.pathname.includes('/admin/company/settings')?styles.selected:''}><MdSettings size={30} className={styles.menuIcon} />{isSidebarOpen?"Settings":""}   </li>
                    <li onClick={() => navigate('/admin/credits')} className={location.pathname === '/admin/credits'?styles.selected:''}><FiCreditCard size={30} className={styles.menuIcon} />{isSidebarOpen?"Credits":""} </li>
                </ul>
            )}
            {auth?.user?.isSuperAdmin && (
                <ul className={styles.menu}>
                    {isSidebarOpen?
                <li className={location.pathname === '/superadmin'?styles.selected:''}><b>Super Admin</b></li>:""}
                <li onClick={() => navigate('/superadmin/companies')} className={location.pathname.includes('/superadmin/companies')?styles.selected:''}><AiOutlineUser size={30} className={styles.menuIcon} /> {isSidebarOpen?"Companies":""}</li>
                <li onClick={() => navigate('/superadmin/leads')} className={location.pathname.includes('/superadmin/leads')?styles.selected:''}><MdLockOutline size={30} className={styles.menuIcon} />{isSidebarOpen?"Leads":""} </li>
                <li onClick={() => navigate('/superadmin/users')} className={location.pathname.includes('/superadmin/users')?styles.selected:''}><MdLockOutline size={30} className={styles.menuIcon} />{isSidebarOpen?"Users":""} </li>
            </ul>
            )}
        </div>
    );

};

export default memo(Sidebar);