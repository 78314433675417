import {memo} from 'react';
import ReactSelect from 'react-select';

import styles from './Select.module.scss';

const Select = ({name,value,options,onChange,readOnly = false,isClearable = false}) => {
    const formattedOptions = options.map(option => {
        if (typeof option !== 'object') {
          return {
            value: option,
            label: option,
          };
        }
        return option;
      });

    const selectedOption = (value || value === 0)
      ? formattedOptions.filter(item => item.value === value)?.pop()
      : null;

    return (
        <div className={styles.root}>
            <ReactSelect
              menuPlacement="auto"
              name={name}
              styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
              menuPortalTarget={document.body} 
              value={selectedOption}
              options={formattedOptions}
              isDisabled={readOnly}
              isClearable={isClearable}
              onChange={onChange}
            />
        </div>
    );

};

export default memo(Select);