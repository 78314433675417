import { memo, useReducer } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import AuthLayout from 'views/layout/AuthLayout';
import styles from './Login.module.scss';

import { useAuth } from 'components/ProvideAuth/hook';
import Input from 'components/Form/Input';
import Button from 'components/Button';
import Group from 'components/Group';
import basicReducer from 'reducers/basicReducer';
import { toast } from 'react-toastify';
import localStorage from 'shared/utils/localStorage';
import { encode,decode } from 'js-base64';

const Login = props => {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    let [state, setState] = useReducer(basicReducer, {
        errors: {},
        submitInProgress: false,
        agree: false,
    });

    let savedUserPass = localStorage.get('up') ?? '';
    let savedUser = '';
    let savedPass = '';
    if(savedUserPass){
        try{
            let upDecoded = decode(savedUserPass);
            let upSplit = upDecoded?.split?.(':::');
            if(upSplit && Array.isArray(upSplit) && upSplit?.length > 1){
                savedUser = upSplit[0];
                savedPass = upSplit[1];
            }
        }catch(e){

        }
    }

    let [form, setForm] = useReducer(basicReducer, {
        email: savedUser,
        password: savedPass,
    });

    let { from }: any = location?.state || { from: { pathname: "/home" } };
    
    const isValid = () => {
        let isValid = true,errors:any = {};

        // if (!state?.agree) {
        //     errors.agree = 'You have to agree to our terms and conditions before you can proceed to login.';
        //     isValid = false;
        // }

        setState({
            errors,
        });
        return { isValid, errors };
    }

    const doLogin = () => {
        const isValidResponse = isValid();

        if (!isValidResponse.isValid) {
            setState({ submitInProgress: false });

            for(let key of Object.keys(isValidResponse.errors)){
                toast.error(isValidResponse.errors[key]);
                break;
            };
            return;
        }
        auth.signin(form,() => {   
            navigate(from);
           
        });
    }

    const isRememberMeClicked = rememberMe => {
        if(rememberMe?.target?.checked){
            let up = encode(`${form.email}:::${form.password}`);
            localStorage.set('up',up);
        }
    };

    return (
        <AuthLayout>
            <div className={styles.root}>
                    <div className="text-center*">
                        <h3>Login</h3>
                    </div>
                    <form className="user">
                        <Group>
                            <Input
                                type="email"
                                className="form-control form-control-user"
                                value={form?.email} 
                                name="email"
                                onChange={email => setForm({ email })}
                                placeholder="Enter email" />
                        </Group>
                        <Group>
                            <Input
                                type="password"
                                className="form-control form-control-user"
                                value={form?.password} name="password"
                                onChange={password => setForm({ password })}
                                placeholder="Password" />
                        </Group>
                        <Group>
                            <div style={{fontSize:12}}>By using our services you agree to our <a href="https://continuityinnovations.com/termsofuse/" rel="noopener noreferrer" target="_blank">Terms of Use</a> and <a href="https://continuityinnovations.com/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a></div>
                        </Group>
                        <Group>
                            <div className="custom-control custom-checkbox small">
                                <input onChange={isRememberMeClicked} onClick={isRememberMeClicked} type="checkbox" className="custom-control-input" id="customCheck" />
                                <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                            </div>
                        </Group>
                        <Group>
                            <Button type="button" view="danger" onClick={doLogin}>Log In</Button>
                        </Group>
                            

                    </form>
                    {/* <hr /> */}
                    <br />
                    {/* <Group className="text-left">
                        <Link className="small link-red" to="/register">Create an Account!</Link>
                    </Group>
                    <br /> */}
                    <Group className="text-left">
                        <Link className="small link-red" to="/forgot-password">Forgot Password?</Link>
                    </Group>

                


            </div>
        </AuthLayout>
    );

};

export default memo(Login);