import {useState,memo} from 'react';
import { Marker, InfoWindow } from "react-google-maps";
import styles from './Map.module.scss';
// import Button from 'components/Button';
// import { FaTrashAlt,FaEdit } from 'react-icons/fa';
// import officeIcon from 'assets/office-icon.png';
import officeIcon from 'assets/icons/building.png';
import fireIcon from 'assets/icons/fire.png';
import waterIcon from 'assets/icons/water.png';
import chemicalIcon from 'assets/icons/chemical.png';
import cyberIcon from 'assets/icons/cyber.png';
import earthquakeIcon from 'assets/icons/earthquake.png';
import activeShooterIcon from 'assets/icons/active-shooter.png';
import powerlossIcon from 'assets/icons/power-loss.png';
import snowIcon from 'assets/icons/snow.png';
import stormIcon from 'assets/icons/storm.png';
import pinIcon from 'assets/icons/pin.png';
import windIcon from 'assets/icons/wind.png';
import tsunamiIcon from 'assets/icons/tsunami.png';
import tornadoIcon from 'assets/icons/tornado.png';
import hurricaneIcon from 'assets/icons/hurricane.png';
import { MAP_SETTINGS } from './constants';
import { useNavigate } from 'react-router-dom';

// const iconsSet = [
//     {name:'fire',icon:fireIcon},
//     {name:'water',icon:waterIcon},
//     {name:'chemical',icon:chemicalIcon},
//     {name:'cyber',icon:cyberIcon},
//     {name:'earthquake',icon:earthquakeIcon},
//     {name:'activeshooter',icon:activeShooterIcon},
//     {name:'powerloss',icon:powerlossIcon},
//     {name:'snow',icon:snowIcon},
//     {name:'storm',icon:stormIcon},
//     {name:'pin',icon:pinIcon},
//     {name:'wind',icon:windIcon},
//     {name:'tsunami',icon:tsunamiIcon},
//     {name:'tornado',icon:tornadoIcon},
//     {name:'hurricane',icon:hurricaneIcon},
// ];

const whichIcon = type => {
    switch(type){
        case "office":{
            return officeIcon;
        }
        case "fire":{
            return fireIcon;
        }
        case "water":{
            return waterIcon;
        }
        case "chemical":{
            return chemicalIcon;
        }
        case "cyber":{
            return cyberIcon;
        }
        case "earthquake":{
            return earthquakeIcon;
        }
        case "activeshooter":{
            return activeShooterIcon;
        }
        case "powerloss":{
            return powerlossIcon;
        }
        case "snow":{
            return snowIcon;
        }
        case "storm":{
            return stormIcon;
        }
        case 'storm':
            return stormIcon;
        case 'pin':
            return pinIcon;
        case 'wind':
            return windIcon;
        case 'tsunami':
            return tsunamiIcon;
        case 'tornado':
            return tornadoIcon;
        case 'hurricane':
            return hurricaneIcon;
    
        default:
            return pinIcon;
    }
}

const {
    MARKER_SIZE,
} = MAP_SETTINGS;

const MyMarker = ({ id, place,type,eventId,mapRef }) => {
    const [visible,toggle] = useState(false);
    const navigate = useNavigate();

    const goToEvent = () => {
        navigate(`/events/${eventId}/playbook`);
    }

    const _onClick = e => {
        toggle(!visible);
        // onClick(e, place);
    }

    return type === 'office'?(
        <Marker
            id={id}
            position={{
                lat: place?.position?.lat,
                lng: place?.position?.lng
            }}
            icon={{
                url: officeIcon,
                scaledSize: new window.google.maps.Size(MARKER_SIZE, MARKER_SIZE),
            }}
            onClick={_onClick}>
            {visible && (<InfoWindow><div>
                <b>{place?.name}</b>
                <div>{place?.address}</div>
            </div></InfoWindow>)}
        </Marker>
    ):(
        <Marker
            id={id}
            position={{
                lat: place?.position?.lat,
                lng: place?.position?.lng
            }}
            icon={{
                url: whichIcon(place?.icon),
                scaledSize: new window.google.maps.Size(45, 45),
            }}
            onClick={_onClick}>
            {visible && (<InfoWindow><div>
                <div style={{textAlign:'center',padding: 5}}><div className={styles.header} onClick={goToEvent}>{place?.name}</div></div>
                <div style={{textAlign:'center'}}><img height="40" src={whichIcon(place?.icon)} /></div>
                <div style={{textAlign:'center',padding: 5}}><div className={styles.link} onClick={goToEvent}>View Event Page</div></div>
            </div></InfoWindow>)}
        </Marker>
    );
}

export default memo(MyMarker);