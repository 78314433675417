import internalApi from 'shared/utils/internalApi';

export const getAllPermissions = () => internalApi.get('role/permission/list');
export const fetchUserPermissions = () => internalApi.get('role/permission/me');
export const fetchRoles = ({ companyId = '',search = '', page = 1, limit = 50, individualSearchFields = [] } = {}) => {
    const queryParams = {
        search,
        companyId,
        page,
        limit,
        individualSearchFields
      };
    return internalApi.get('role/list', { params: queryParams })
};

// export const exportCSV = checkedItems => () => {
//     internalApi.get('role/export', {
//         params: { checkedItems: checkedItems.join(',') },
//       }).then(res => {
//         exportCSVFile(res.payload.item, 'Roles');
//       });
//   };

export const getRoleById = id => internalApi.get(`role/one/${id}`);
export const saveRole = data => {
    return internalApi.post(
      data.id ? `role/update/${data.id}` : `role/create`,
      data,
    );
  };

export const deleteRoles = ids =>
  internalApi.post('role/delete-multiple', {
    ids,
  });