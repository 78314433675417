import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cs from './component.module.scss';
import { IoMdClose } from 'react-icons/io';

const Modal = props => {
  const rootClassName = props.isOpen ? '' : cs.hidden;
  const contentStyle = {
    maxWidth:props.maxWidth ? props.maxWidth : 540,
    padding: props.hasPadding ? '20px 20px 30px' : 0,
    overflowY: props.overflow,
    overflowX: props.overflowX,
    maxHeight: `calc(100vh - ${props.heightMargin}px)`,
    ...props.style,
  };

  let extraStyle = {};
  if(props?.style?.zIndex){
    extraStyle={style:{zIndex:(props?.style?.zIndex)}};
  }

  if(props.portal){
    return ReactDOM.createPortal(
      <div className={rootClassName}>
        <div className={cs.overlay} {...extraStyle} onClick={props.onClose} role="presentation" />
        <div className={cs.content} style={contentStyle}>
          {props.title && (
            <div style={{ paddingBottom: 10,display:'flex',justifyContent:'space-between',fontSize:"1.42857143em",fontWeight:500,lineHeight:1.2,letterSpacing: '-.008em',marginBottom:16 }}>
              <span>{props.title}</span>
              <IoMdClose style={{cursor:'pointer'}} onClick={props?.onClose} />
            </div>
          )}
          {props.children}
        </div>
      </div>
      ,
      document.getElementsByTagName('body')[0]
    );
  }else{
    return (
      <div className={rootClassName}>
        <div className={cs.overlay} onClick={props.onClose} role="presentation" />
        <div className={cs.content} style={contentStyle}>
          {props.title && (
            <div style={{ paddingBottom: 10,display:'flex',justifyContent:'space-between',fontSize:"1.42857143em",fontWeight:500,lineHeight:1.2,letterSpacing: '-.008em',marginBottom:16 }}>
              <span>{props.title}</span>
              <IoMdClose style={{cursor:'pointer'}} onClick={props?.onClose} />
            </div>
          )}
          {props.children}
        </div>
      </div>
    );
  }

};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  hasPadding: PropTypes.bool,
  portal: PropTypes.bool,
  overflow: PropTypes.string,
  heightMargin: PropTypes.number,
  style: PropTypes.shape({}),
//  className:PropTypes.string
};

Modal.defaultProps = {
  title: null,
  hasPadding: true,
  overflow: 'scroll',
  heightMargin: 180,
  style: {},
  portal: true
};

export default Modal;
