import {memo,useEffect,useReducer} from 'react';
import styles from './IdleLogout.module.scss';
import IdleTimer from 'shared/utils/IdleTimer';
import basicReducer from 'reducers/basicReducer';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { useAuth } from 'components/ProvideAuth/hook';
import { useNavigate } from 'react-router-dom';
import { sub,unsub } from 'shared/utils/pubsub';
import internalApi from 'shared/utils/internalApi';

let timer;
let logoutTimer=null;
const INACTIVITY_MINUTES = 10;
const HEARBEAT_ALIVE_MINUTES = 5;

const IdleLogout = props => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [state,setState] = useReducer(basicReducer,{
        showModal:false,
        timeRemaining:120
    });

    useEffect(() => {
        let onLogout = (data) => {
            auth.signout(() => {
                navigate('/login');
            });
        }

        sub('logout',onLogout);

        return () => {
            unsub('logout',onLogout);
        }
    },[]);

    useEffect(() => {
        let heartbeatHandler = setInterval(() => {
            if(auth.user){
                internalApi.post('/heartbeat/alive').then(res => {
                });
            }
        },1000 * 60 * HEARBEAT_ALIVE_MINUTES);

        return () => {
            clearInterval(heartbeatHandler);
        }
    },[]);

    useEffect(() => {
        timer = new IdleTimer(() => {
            // this.logOut();
            setState({showModal:true});
        }, 1000 * 60 * INACTIVITY_MINUTES);
        timer.activate();
      
        return () => {
            timer.deactivate();
            timer=null;
            if(logoutTimer){
                // console.log('clearInterval from useEffect[]')
                clearTimeout(logoutTimer);
                logoutTimer=null;
            }
        }
    },[]);

    useEffect(() => {
        if(state?.showModal){
            if(logoutTimer){
                // console.log('clearInterval from useEffect[state?.showModal]=true')
                clearInterval(logoutTimer);
            }
            logoutTimer = setTimeout(updateLogoutRemaining, 1000 * 1);
        }else{
            if(logoutTimer){
                // console.log('clearInterval from useEffect[state?.showModal]=false')
                setState({timeRemaining:120});
                clearTimeout(logoutTimer);
            }
        }
    },[state?.showModal,state?.timeRemaining]);

    const updateLogoutRemaining = () => {
        if(state?.timeRemaining > 0){
            setState({timeRemaining:state?.timeRemaining-1});
        }else{
            if(logoutTimer){
                // console.log('clearInterval from updateLogoutRemaining')
                clearTimeout(logoutTimer);
            }

            setState({timeRemaining:120,showModal:false});
            logout();
        }
    }

    const logout = () => {
		auth.signout(() => {
			navigate('/login');
		});
	}

    const closeModal = () => {
        setState({showModal:false});
        if(timer){
            timer.resetTimer();
        }
    }

    if(state?.showModal){
        return (
            <Modal title="Session Timeout" isOpen={true} onClose={closeModal}>
                You are being timed-out due to inactivity. You will be logged out from the system if you don't take any action in the next 2 minutes.
                <div style={{marginTop:20}}>
                    <Button style={{marginRight:15}} onClick={closeModal} view="success">Yes, stay signed in</Button> <Button onClick={logout}>Logout ({state?.timeRemaining}s remaining)</Button>
                </div>
            </Modal>
        );
    }else
    return null;

};

export default memo(IdleLogout);