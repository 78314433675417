// import { IRootState } from 'shared/interface';
// import { store } from 'shared/store/store';

export const hasPerm = (perm,userPermissions) => {
  // const state:IRootState = store.getState();
  // console.log('hasPerm::userPermissions ->',userPermissions);
  const isAdmin = userPermissions?.isAdmin;
  const isSuperAdmin = userPermissions?.isSuperAdmin;
  
  if(isSuperAdmin || isAdmin){
    console.warn('** has permissions due to being super admin or admin outside of roles.');
    return true;
  }
  
  const permissions = userPermissions?.permissions;
  // console.log('hasPerm::permissions-> ',perm,permissions);
  let out = permissions?.some?.(item => item?.name === perm);
  // console.log(perm,out);
  return out;
};

/**
 * Super Admin
 */
export const isAdmin = () => 'perm_admin_view'
export const isSuperAdmin = () => 'perm_superadmin_view'
export const canAdministrationMenu = () => 'perm_administration_menu_view'

/**
 * Users
 */
export const canViewUsers = () => 'perm_users_view'
export const canCreateUsers = () => 'perm_users_create'
export const canUpdateUsers = () => 'perm_users_update'
export const canDeleteUsers = () => 'perm_users_delete'
export const canExportUsers = () => 'perm_users_export'

/**
 * Events
 */
export const canViewEvents = () => 'perm_events_view'
export const canCreateEvents = () => 'perm_events_create'
export const canUpdateEvents = () => 'perm_events_update'
export const canDeleteEvents = () => 'perm_events_delete'
export const canExportEvents = () => 'perm_events_export'

/**
 * Events
 */
export const canViewDocumentManager = () => 'perm_document_manager_view'
export const canCreateDocumentManager = () => 'perm_document_manager_create'
export const canUpdateDocumentManager = () => 'perm_document_manager_update'
export const canDeleteDocumentManager = () => 'perm_document_manager_delete'
export const canExportDocumentManager = () => 'perm_document_manager_export'

/**
 * Groups
 */
export const canViewGroups = () => 'perm_groups_view'
export const canCreateGroups = () => 'perm_groups_create'
export const canUpdateGroups = () => 'perm_groups_update'
export const canDeleteGroups = () => 'perm_groups_delete'
export const canExportGroups = () => 'perm_groups_export'

/**
 * Locations
 */
export const canViewLocations = () => 'perm_locations_view'
export const canCreateLocations = () => 'perm_locations_create'
export const canUpdateLocations = () => 'perm_locations_update'
export const canDeleteLocations = () => 'perm_locations_delete'
export const canExportLocations = () => 'perm_locations_export'

/**
 * People
 */
export const canViewPeople = () => 'perm_people_view'
export const canCreatePeople = () => 'perm_people_create'
export const canUpdatePeople = () => 'perm_people_update'
export const canDeletePeople = () => 'perm_people_delete'
export const canExportPeople = () => 'perm_people_export'

/**
 * Playbook
 */
export const canViewPlaybook = () => 'perm_playbook_view'
export const canCreatePlaybook = () => 'perm_playbook_create'
export const canUpdatePlaybook = () => 'perm_playbook_update'
export const canDeletePlaybook = () => 'perm_playbook_delete'
export const canExportPlaybook = () => 'perm_playbook_export'

/**
 * Roles
 */
export const canViewRoles = () => 'perm_roles_view'
export const canCreateRoles = () => 'perm_roles_create'
export const canUpdateRoles = () => 'perm_roles_update'
export const canDeleteRoles = () => 'perm_roles_delete'
export const canExportRoles = () => 'perm_roles_export'

/**
 * Company
 */
export const canUpdateCompanySettings = () =>
  'perm_company_settings_update'