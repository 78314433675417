import {Suspense} from 'react';
import { BrowserRouter } from 'react-router-dom';
import ProvideAuth from 'components/ProvideAuth';
import Root from 'routes/Root';
import Loading from 'components/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <ProvideAuth>
      <ToastContainer />
      <Suspense fallback={<Loading fixed={true} size="sm" type="ball-pulse-sync" />}>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </Suspense>
    </ProvideAuth>
  );
}

export default App;
