import {memo} from 'react';
import { Route,useLocation } from 'react-router-dom';
import { useAuth } from 'components/ProvideAuth/hook';
//import styled from 'styled-components';
// import styles from './PrivateRoute.module.scss';


const PrivateRoute = ({ element,children = null, ...rest }) => {
  // let navigate = useNavigate();
  // let location = useLocation();
  let auth = useAuth();

  if(auth?.user && auth?.user?.isSuperAdmin){
    return element;
  }

  return (<div>Not allowed to view this content</div>);
};

export default memo(PrivateRoute);