import internalApi from 'shared/utils/internalApi';

export const fetchItems = ({ search = '', page = 1, limit = 50, individualSearchFields = [] } = {}) => {
  const queryParams = {
    search,
    page,
    limit,
    individualSearchFields
  };
  return internalApi.get('playbook/list', { params: queryParams })
};

// export const exportCSV = checkedItems => () => {
//     internalApi.get('role/export', {
//         params: { checkedItems: checkedItems.join(',') },
//       }).then(res => {
//         exportCSVFile(res.payload.item, 'Roles');
//       });
//   };



export const getItemById = id => internalApi.get(`playbook/one/${id}`);
export const copyPlaybook = id => internalApi.post(`playbook/copy/${id}`);
export const saveItem = data => {
  return internalApi.post(
    data.id ? `playbook/update/${data.id}` : `playbook/create`,
    data,
  );
};

export const deleteItem = ids =>
  internalApi.post(`playbook/delete/${ids}`, {
    ids,
  });

export const activatePlaybook = (playbookId, eventName) => internalApi.post(`playbook/activate/${playbookId}`, { name: eventName });

export const deleteActionItem = (id, item) => internalApi.post(`playbook/deleteActionItem/${id}`, item);

export const getActionItems = playbookId => internalApi.get(`playbook/listActionItems/${playbookId}`);

export const createActionItem = (playbookId, data) => {
  let params = { ...data };
  if (typeof params?.id !== 'undefined') {
    delete params.id;
  }

  return internalApi.post(`playbook/addActionItem/${playbookId}`, params);
};

export const updateActionItem = data => {
  let params = { ...data };
  if (params?.id) {
    delete params.id;
  }
  return internalApi.post(`playbook/updateActionItem/${data.id}`, params);
};

export const saveUpdateItemOrder = (records, dragIndex, hoverIndex) => {
  let rec = records?records?.map?.(r => ({id:r.id})):[];
  const queryParams = { records:rec, dragIndex, hoverIndex };
  return internalApi.post(`playbook/updateDisplayOrder`, queryParams);
}
export const exportPlaybookActionsExcel = (playbookId) => internalApi.get(`playbook/export/actionExcel`, { isFileDownload: true,params:{playbookId} });

export const exportPlaybookActionsCSV = (playbookId) => internalApi.get(`playbook/export/actionCSV`,{ params:{playbookId} });
