import { memo,useState } from "react";
import cx from "classnames";
import Loading from "../Loading";
import styles from "./Button.module.scss";
// import swal from "sweetalert";
// import swalr from '@sweetalert/with-react';
import Modal from './../Modal';

const Button = ({
    children,
    view = "",
    size = "",
    disabled = false,
    isLoading = false,
    onClick = null,
    isFromDelete = false,
    ...rest
}) => {
    const [showDeleteModal,toggleDeleteModal] = useState(false);
    const onButtonClick = (e) => {
        if (onClick && !disabled) {
            if (isFromDelete) {
                toggleDeleteModal(true);
            }else{
                onClick(e);
            }
        }
    };

    const hideDeleteModal = () => {
        toggleDeleteModal(false);
    }

    return (
        <>
        <div
            {...rest}
            onClick={onButtonClick}
            className={cx(
                styles.root,
                styles?.[view],
                styles?.[size],
                disabled ? styles.disabled : ""
            )}
        >
            {isLoading && (
                <div
                    style={{
                        position: "relative",
                        height: "10px",
                        width: 80,
                        left: "-25%",
                    }}
                >
                    <Loading
                        isLight={true}
                        size="sm"
                        fixed={false}
                        type="ball-pulse-sync"
                    />
                </div>
            )}
            {!isLoading && <div className={styles.inner}>{children}</div>}
        </div>
        {showDeleteModal && (
                <Modal isOpen={true} title={`Confirmation`} onClose={hideDeleteModal}>
                    <div>
                        Are you sure you want to delete this item?
                    </div>
                    <div style={{paddingTop:20,textAlign:'right'}}>
                    <Button style={{marginRight:10}} onClick={e => { hideDeleteModal();onClick(e)}}>Yes</Button>
                    <Button view="secondary" onClick={hideDeleteModal}>No</Button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default memo(Button);
