import {memo} from 'react';
import cx from 'classnames';
import styles from './Group.module.scss';

const Group = ({children,label = '',vertical = true,...rest}:{label?:any;[n:string]:any}) => {

    return (
        <div className={styles.root} {...rest}>
            {label && (
                <div className={styles.label}>{label}</div>
            )}
            <div className={cx(vertical?'':styles.horizontal)}>
                {children}
            </div>
        </div>
    );

};

export default memo(Group);