import {memo} from 'react';
import styles from './HomeWidget.module.scss';
import Card from 'components/Card';

const HomeWidget = ({label,children,icon}) => {

    return (
        <div className={styles.root}>
            <Card style={{width:190}}>
                <div>
                    <div className={styles.label}>{label}</div>
                    <div className={styles.number}>
                    {children}
                    </div>
                </div>
                <div className={styles.icon}>
                    {icon}
                </div>
            </Card>
        </div>
    );

};

export default memo(HomeWidget);