import {memo,useEffect,useReducer} from 'react';
import styles from './CreateEvent.module.scss';
import Modal from 'components/Modal';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Group from 'components/Group';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import basicReducer from 'reducers/basicReducer';
import { fetchItems, activatePlaybook } from 'shared/api/playbook';
import { saveItem } from 'shared/api/events';


const CreateEvent = ({onClose}) => {
    const [state, setState] = useReducer(basicReducer, {
		list: [],
		selectedPlaybookId: null,
		name: '',
	});
    // const [name,setName] = useState('');

    const _onCreate = async () => {
        if(state?.name && state?.name?.trim()){
            // onCreate(state?.name);
                let selectedPlaybookId = state?.selectedPlaybookId;
                // setState({ showEventModal: false, selectedPlaybookId: null });
                if(selectedPlaybookId){
                    activatePlaybook(selectedPlaybookId, state?.name).then(activated => {
                        onClose(null,activated?.payload?.item?.id);
                        // console.log('activated-> ',activated);
                        // navigate(`/events/${activated?.payload?.item?.id}/playbook`);
                    });
                }else{
                    saveItem({name:state?.name}).then(activated => {
                        onClose(null,activated?.payload?.data?.id);
                        // navigate(`/events/${activated?.payload?.item?.id}/playbook`);

                    });
                }
            
        }else{
            toast.error("Event name cannot be empty");
        }
    }

    const listPlaybooks = () => {
        fetchItems({ limit: 1000, page: 1 }).then(list => {
			let formattedData = [];
			if (list?.payload?.items?.records) {
				// console.log('list?.payload?.items?.records-> ',list?.payload?.items?.records);
				formattedData = list?.payload?.items?.records?.map(item => ({
					value: item?.id,
					label: item?.name,
				}))
				
				setState({ totalCount: list.payload.items.total })
			}
			setState({ list: formattedData });
		});
    }

    useEffect(() => {
        listPlaybooks();
    },[]);

    return (
        <Modal title="Create Event" isOpen={true} onClose={onClose}>
            <div className={styles.root}>
                <Group label="Event Name">
                    <Input value={state?.name} onChange={name => setState({name})} />
                </Group>
                <Group label="Use Playbook (Optional)">
                    <Select value={state?.selectedPlaybookId} name="playbookId" options={state?.list}  onChange={v => setState({selectedPlaybookId:v?.value})}  />
                </Group>
                <Group>
                    <Button onClick={onClose} view="secondary" style={{marginRight:10}}>Cancel</Button>
                    <Button onClick={_onCreate}>Create Event</Button>
                </Group>
            </div>
        </Modal>
    );

};

export default memo(CreateEvent);