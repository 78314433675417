import { useEffect, useReducer } from 'react';
import playboocuser from '../../assets/playbooc-user.png';
import styles from '../../views/protected/Home/Home.module.scss';
import { fetchNotifications } from 'shared/api/header';
import internalApi from "shared/utils/internalApi";
import basicReducer from 'reducers/basicReducer';
import { useSocket } from 'components/SocketBase/hook';
import { useNavigate } from 'react-router-dom';

const NotificationData = () => {
    const socket = useSocket();
    const navigate = useNavigate();
    const [state, setState] = useReducer(basicReducer, {
        messages: [],
        isSocketUpdated: ''
    });
    useEffect(() => {
        fetchNotifications().then(list => {
            const rec = list?.payload?.items?.records;
            if (rec) {
            // console.log('messages ->',rec);
                setState({ messages: rec });
            }
        });
    }, [state.isSocketUpdated]);

    useEffect(() => {
        // let socket = io(internalApi.webSocketUrl(), { transports: ['websocket'] });
        socket.on("notification", function (data: any) {
            // console.log('socket data:- ' + data);
            setState({ isSocketUpdated: data });
        });

        // return () => {
        //     // socket.off("notification");
        // }
    }, []);

    const openRelevantWindow = data => {
        if(data?.meta?.info?.eventId){
            let eventId = data?.meta?.info?.eventId;
            // console.log('opening event ,', eventId);
            navigate(`/events/${eventId}/playbook`);
        }
    }

    return (
        <>
            <div>
                {
                    state.messages.map((data,index) => (
                        <div key={index} className={styles.flexNotification}>
                            {/* <div style={{ position:'relative' }}>
                                <img style={{ marginLeft: 10 }} src={playboocuser} />
                            </div> */}
                            <div style={{ marginLeft: 20 }}>
                                <h4 onClick={() => openRelevantWindow(data)} style={{cursor:'pointer'}} className="headerNotification">
                                <div style={{  backgroundColor: "#d84857",borderRadius:'50%',width:10,height:10,position: 'absolute',left: -25 }}></div>
                                    {data.text}</h4>
                                <p className="para" dangerouslySetInnerHTML={{__html:data.meta?.message}} />
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}
export default NotificationData;