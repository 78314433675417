import {memo} from 'react';
import styles from './AuthLayout.module.scss';
import logo from 'assets/playbook.png';

const AuthLayout = ({children}) => {

    return (
        <div className={styles.root}>
            <div className={styles.logo}>
                <img src={logo} alt="Playbooc" />
            </div>
            <div className={styles.contentWrapper}>
                {children}
            </div>
        </div>
    );

};

export default memo(AuthLayout);