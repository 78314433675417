import { memo, useEffect, useReducer, useState } from 'react';
//https://morioh.com/p/9dd80c962d37
// https://twitter.com/i/lists/1429800649676316673
// https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/scripting-factory-functions
import { TwitterTimelineEmbed, /*TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton*/ } from 'react-twitter-embed';
import styles from './Home.module.scss';
// import { FaCalendar, FaLocationArrow, FaUsers, FaBook } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'components/Button';
// import { FaPlus } from 'react-icons/fa'
// import { HiOutlineDotsVertical } from 'react-icons/hi'
import HomeWidget from 'components/HomeWidget';
import { useAuth } from 'components/ProvideAuth/hook'
import { useSidebarVisible } from 'hooks/sidebar';
// import ListEvents from '../ListEvents/ListEvents'
import basicReducer from 'reducers/basicReducer';
import { fetchItems as fetchEvent } from 'shared/api/events';
import { fetchItems as fetchListPlaybook } from 'shared/api/playbook';
import { fetchItems as fetchListLocation } from 'shared/api/location';
import { fetchItems as fetchListGroup } from 'shared/api/group';
import NotificationData from '../../../components/NotificationData/NotificationData';
import { MdEventAvailable, MdFormatListBulleted, MdPeopleOutline, MdLockOutline, MdHome } from 'react-icons/md'
import { GoLocation } from 'react-icons/go';
import cx from 'classnames';
import CreateEvent from '../Event/CreateEvent';
import Map from './Map';
import { VscSymbolEvent } from 'react-icons/vsc';
import { BsListCheck } from 'react-icons/bs';
import { AiFillThunderbolt, AiOutlineTeam } from 'react-icons/ai';
import { getItemById as getCompanyDetails } from 'shared/api/company';
import { copyAttr } from 'shared/utils/common';


var tempSearchValue = '';

const Home = props => {
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const isSidebarOpen = useSidebarVisible();
	const [state, setState] = useReducer(basicReducer, {
		list: [],
		pageSize: 100,
		eventTotalCount: 0,
		openEventTotalCount: 0,
		playBooktotalCount: 0,
		peopleTotalCount: 0,
		groupTotalCount: 0,
		listTotalCount: 0,
		showCreateEventPopup: false
	});

	const [company, setCompany]: [any, any] = useState({
		id: '',
		name: '',
		mainContact: '',
		twilioPhoneNo: '',
		twilioSID: '',
		twilioToken: '',
		meta: {}
	});

	const loadRecords = ({ action, value, pageSize = 100, pageIndex = 1 }: any) => {
		let filter = { search: tempSearchValue };
		filter = Object.assign(filter, { limit: pageSize || 100, page: pageIndex });

		//fetch events count
		fetchEvent(filter).then(list => {
			if (list?.payload?.items?.records) {
				// console.log('list?.payload?.items?.records-> ',list?.payload?.items?.records);
				// openEventTotalCount
				setState({
					openEventTotalCount: list?.payload?.items?.records?.filter?.(i => i.status === 'open')?.length,
					eventTotalCount: list?.payload?.items?.length
				})
			}
		});
		//fetch playbook list count
		fetchListPlaybook(filter).then(list => {
			if (list?.payload?.items?.records) {
				setState({ playBooktotalCount: list.payload.items.total })
			}
		});

		//fetch location count
		fetchListLocation(filter).then(list => {
			if (list?.payload?.items?.records) {
				setState({ listTotalCount: list.payload.items.total })
			}
		});

		fetchListGroup().then(list => {
			// console.log('list-> ',list);
			if (list?.payload?.items?.records) {
				setState({ groupTotalCount: list.payload.items.total })
			}
		});
	}
	useEffect(() => {
		loadRecords({ action: 'loadList' });
	}, []);

	useEffect(() => {
		if (auth?.user?.companyId) {
			getCompanyDetails(auth?.user?.companyId).then(out => {
				let toSave = copyAttr(company, out?.payload?.item);
				// console.log(toSave);
				setCompany(toSave);
			});
		}
	}, [auth?.user?.companyId]);


	const onCloseEvent = (ev, eventId) => {
		if (eventId) {
			navigate(`/events/${eventId}/playbook`);
		}
		setState({ showCreateEventPopup: false });
	}

	let twitterId = auth?.user?.meta?.twitter?.listId || auth?.company?.meta?.twitter?.listId;

	return (
		<div className={styles.root}>
			<div className={styles.quickWidgetsHolder}>
				<div className={styles.quickActionsHolder}>
					<h3>Quick Actions</h3>
					<br />
					<Button style={{ display: 'inline-block', backgroundColor: "white", color: "#d84857", border: "solid", marginRight: 10 }} onClick={() => setState({ showCreateEventPopup: true })} className={location.pathname.includes('/events') ? styles.selected : ''}><span className={styles.plus}>+</span>Create Event</Button>
					<Button style={{ display: 'inline-block', backgroundColor: "white", color: "#d84857", border: "solid" }} onClick={() => navigate('/playbooks/add')} className={location.pathname.includes('/playbooks') ? styles.selected : ''}><span className={styles.plus}>+</span> Create Playbook</Button>
				</div>
				<div className={styles.widgets}>
					<HomeWidget label="Open Events" icon={<AiFillThunderbolt size={30} style={{ cursor: 'pointer' }} onClick={() => navigate('/events/')} />}><span style={{ cursor: 'pointer' }} onClick={() => navigate('/events/')}>{state?.openEventTotalCount}</span></HomeWidget>
					<HomeWidget label="Playbooks" icon={<BsListCheck size={30} style={{ cursor: 'pointer' }} onClick={() => navigate('/playbooks/')} />}><span style={{ cursor: 'pointer' }} onClick={() => navigate('/playbooks/')}>{state?.playBooktotalCount}</span></HomeWidget>
					<HomeWidget label="Groups" icon={<AiOutlineTeam size={30} style={{ cursor: 'pointer' }} onClick={() => navigate('/groups/')} />}><span style={{ cursor: 'pointer' }} onClick={() => navigate('/groups/')}>{state?.groupTotalCount}</span></HomeWidget>
					<HomeWidget label="Locations" icon={<GoLocation size={30} style={{ cursor: 'pointer' }} onClick={() => navigate('/locations/')} />}><span style={{ cursor: 'pointer' }} onClick={() => navigate('/locations/')}>{state?.listTotalCount}</span></HomeWidget>
				</div>
			</div>
			<div style={{ marginTop: 50 }}>
				{company?.meta?.showMapsOnDashboard && (
					<>
						<h3 style={{ marginBottom: 30 }}>Events Map</h3>
						<Map />
					</>
				)}
			</div>
			<div>
				<div className={styles.homeFlex}>

					<div className={cx(twitterId ? styles.col : styles.colfull, styles.shadow)}>
						<div className={styles.flex}>
							<div><h3>Activity</h3>
							</div>

						</div>
						<div className={cx(styles.list, styles.notificationList)}>
							<ul><NotificationData /></ul>
						</div>
					</div>
					{twitterId && (
						<div className={cx(styles.col, styles.shadow)}>
							<TwitterTimelineEmbed
								sourceType="list"
								noFooter
								// noHeader
								noBorders
								transparent
								id={twitterId}
								options={{
									height: 400,
								}}
							/>
						</div>
					)}


				</div>
			</div>
			{/* <div className={styles.myEvents}>
				<h3>My Events</h3>
				<ListEvents isHomeListEvent={true} />
			</div> */}
			{state?.showCreateEventPopup && (
				<CreateEvent onClose={onCloseEvent} />
			)}
		</div>
	);

};

export default memo(Home);