import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

export function createAction(type: string, payload?: any): any {
    payload = typeof payload !== 'undefined' ? payload : {};
    return {
        type,
        payload
    };
};

export const rootAction = (payload: any) => createAction(`SET`, payload);

export const useRootDispatch = () => {
    const rootDispatch = useDispatch();
    const setRootState = useMemo(() => payload => rootDispatch(rootAction(payload)),[]);
    return setRootState;
};