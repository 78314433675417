import React,{memo,lazy,Suspense} from 'react';
import {Routes,Route} from 'react-router-dom';
// import { useSelector } from 'react-redux';

import Loading from 'components/Loading';
import PrivateRoute from 'components/PrivateRoute';
import SuperAdminRoute from 'components/SuperAdminRoute';
// import styles from './App.module.scss';
import * as routeAccess from 'shared/utils/accessControl';

import Home from 'views/protected/Home';
const Playbooks = lazy(() => import('views/protected/Playbooks'));
const EditPlaybook = lazy(() => import('views/protected/Playbooks/EditPlaybook'));
const Event = lazy(() => import('views/protected/Event'));
const ListEvents = lazy(() => import('views/protected/ListEvents'));
const People = lazy(() => import('views/protected/People'));
const Locations = lazy(() => import('views/protected/Locations'));
const EditLocation = lazy(() => import('views/protected/Locations/EditLocation'));
const EditPerson = lazy(() => import('views/protected/People/EditPerson'));
const Groups = lazy(() => import('views/protected/Groups'));
const EditGroup = lazy(() => import('views/protected/Groups/EditGroup'));
const GroupManagePeople = lazy(() => import('views/protected/Groups/Users'));
const EventsPlaybook = lazy(() => import('views/protected/Event/Playbook'));
const EventsUpload = lazy(() => import('views/protected/Event/Upload'));
const EventsChat = lazy(() => import('views/protected/Event/Chat'));
const EventsNotes = lazy(() => import('views/protected/Event/Notes'));
const EventsMap = lazy(() => import('views/protected/Event/Map'));
// const EventsMore = lazy(() => import('views/protected/Event/More'));
const Profile= lazy(()=>import ('views/protected/Profile'));
const DocumentManager= lazy(()=>import ('views/protected/DocumentManager'));
const Credits= lazy(()=>import ('views/admin/Credits'));

// Admin
const AdminCompanySettings = lazy(() => import('views/admin/CompanySettings'));
const AdminRoles = lazy(() => import('views/admin/Roles'));
const AdminEditRole = lazy(() => import('views/admin/Roles/EditRole'));

const AdminUsers = lazy(() => import('views/admin/Users'));
const AdminEditUser = lazy(() => import('views/admin/Users/EditUser'));

const SuperAdminLeads = lazy(() => import('views/superadmin/Leads'));
const SuperAdminEditLead = lazy(() => import('views/superadmin/Leads/EditUser'));

const SuperAdminCompany = lazy(() => import('views/superadmin/Company'));
const SuperAdminEditCompany = lazy(() => import('views/superadmin/Company/EditCompany'));

const SuperAdminUsers = lazy(() => import('views/superadmin/Users'));
const SuperAdminEditUser = lazy(() => import('views/superadmin/Users/EditUsers'));


const App = props => {
    // const perms = useSelector(state => state?.userPermissions?.permissions);

    // console.log('perms-> ',perms);
    return (
        <Suspense fallback={<Loading fixed={false} size="sm" type="ball-pulse-sync" />}>
            <Routes>
                <Route path="/" element={<PrivateRoute element={<Home />} />} />
                <Route path="/home" element={<PrivateRoute element={<Home />} />} />
                
                <Route path="/home/profile" element={<PrivateRoute element={<Profile />} />} />

                <Route path="/Document-manager" element={<PrivateRoute element={<DocumentManager />} permission={routeAccess.canViewDocumentManager()} />} />

                <Route path="/people" element={<PrivateRoute element={<People />} permission={routeAccess.canViewPeople()} />} />
                <Route path="/people/add" element={<PrivateRoute path="/people/add" element={<EditPerson />} permission={routeAccess.canCreatePeople()} />} />
                <Route path="/people/edit/:id" element={<PrivateRoute path="/people/edit/:id" element={<EditPerson />} permission={routeAccess.canUpdatePeople()} />} />
                <Route path="/locations" element={ <PrivateRoute path="/locations" element={<Locations />} permission={routeAccess.canViewLocations()} />} />
                <Route path="/locations/add" element={<PrivateRoute path="/locations/add" element={<EditLocation />} permission={routeAccess.canCreateLocations()} />} />
                <Route path="/locations/edit/:id" element={<PrivateRoute path="/locations/edit/:id" element={<EditLocation />} permission={routeAccess.canUpdateLocations()} />} />
                
                <Route path="/groups" element={<PrivateRoute path="/groups" element={<Groups />} permission={routeAccess.canViewGroups()} />} />
                <Route path="/groups/add" element={<PrivateRoute path="/groups/add" element={<EditGroup />} permission={routeAccess.canCreateGroups()} />} />
                <Route path="/groups/edit/:id" element={<PrivateRoute path="/groups/edit/:id" element={<EditGroup />} permission={routeAccess.canUpdateGroups()} />} />
                <Route path="/groups/manageusers/:id" element={<PrivateRoute path="/groups/manageusers/:id" element={<GroupManagePeople />} permission={routeAccess.canViewGroups()} />} />
                
                <Route path="/playbooks" element={<PrivateRoute path="/playbooks" element={<Playbooks />} permission={routeAccess.canViewPlaybook()} />} />
                <Route path="/playbooks/edit/:id" element={<PrivateRoute path="/playbooks/edit/:id" element={<EditPlaybook />} permission={routeAccess.canUpdatePlaybook()} />} />
                <Route path="/playbooks/add" element={<PrivateRoute path="/playbooks/add" element={<EditPlaybook />} permission={routeAccess.canCreatePlaybook()} />} />
                
                <Route path="/events" element={<PrivateRoute path="/events" element={<ListEvents />} permission={routeAccess.canViewEvents()} />} />
                
                <Route path="/events/:id" element={<PrivateRoute element={<Event />} permission={routeAccess.canViewEvents()} />}>
                    <Route path="" element={<PrivateRoute element={<EventsPlaybook />} />} />
                    <Route path="playbook" element={<PrivateRoute path="playbook" element={<EventsPlaybook />} />} />
                    <Route path="upload" element={<PrivateRoute path="upload" element={<EventsUpload />} />} />
                    <Route path="chat" element={<PrivateRoute path="chat" element={<EventsChat />} />} />
                    <Route path="notes" element={<PrivateRoute path="notes" element={<EventsNotes />} />} />
                    <Route path="map" element={<PrivateRoute path="map" element={<EventsMap />} />} />
                    {/* <Route path="more" element={<PrivateRoute path="more" element={<EventsMore />} />} /> */}
                </Route>

                <Route path="/admin/credits" element={<PrivateRoute element={<Credits />} />} />
                <Route path="/admin/company/settings" element={<PrivateRoute element={<AdminCompanySettings />} />} />
                <Route path="/admin/roles" element={<PrivateRoute element={<AdminRoles />} permission={routeAccess.canViewRoles()} />} />
                <Route path="/admin/roles/edit/:id" element={<PrivateRoute element={<AdminEditRole />} permission={routeAccess.canUpdateRoles()} />} />
                <Route path="/admin/roles/add" element={<PrivateRoute element={<AdminEditRole />} permission={routeAccess.canCreateRoles()} />} />
                
                <Route path="/admin/users" element={<PrivateRoute element={<AdminUsers />} permission={routeAccess.canViewUsers()} />} />
                <Route path="/admin/users/edit/:id" element={<PrivateRoute element={<AdminEditUser />} permission={routeAccess.canUpdateUsers()} />} />
                <Route path="/admin/users/add" element={<PrivateRoute element={<AdminEditUser />} permission={routeAccess.canCreateUsers()} />} />

                <Route path="/superadmin/leads" element={<SuperAdminRoute element={<SuperAdminLeads />} />} />
                <Route path="/superadmin/leads/edit/:id" element={<SuperAdminRoute  element={<SuperAdminEditLead />} />} />
                <Route path="/superadmin/leads/add" element={<SuperAdminRoute  element={<SuperAdminEditLead />} />} />
                <Route path="/superadmin/users" element={<SuperAdminRoute  element={<SuperAdminUsers />} />} />
                
                <Route path="/superadmin/users/edit/:id" element={<SuperAdminRoute  element={<SuperAdminEditUser />} />} />
                <Route path="/superadmin/users/add" element={<SuperAdminRoute  element={<SuperAdminEditUser />} />} />
                <Route path="/superadmin/companies" element={<SuperAdminRoute  element={<SuperAdminCompany />} />} />
                <Route path="/superadmin/companies/edit/:id" element={<SuperAdminRoute  element={<SuperAdminEditCompany />} />} />
                <Route path="/superadmin/companies/add" element={<SuperAdminRoute  element={<SuperAdminEditCompany />} />} />                

            </Routes>
        </Suspense>
    );

};

export default memo(App);