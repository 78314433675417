import internalApi from 'shared/utils/internalApi';

export const fetchItems = ({ search = '', page = 1, limit = 50, individualSearchFields = [] } = {}) => {
  const queryParams = {
    search,
    page,
    limit,
    individualSearchFields
  };
  return internalApi.get('location/list', { params: queryParams })
};

export const exportCSV = () => {
  return internalApi.get('location/export');
};

export const importLocations = assetId => internalApi.post(`location/import/${assetId}`);
export const getItemById = id => internalApi.get(`location/one/${id}`);
export const saveItem = data => {
  return internalApi.post(
    data.id ? `location/update/${data.id}` : `location/create`,
    data,
  );
};

export const deleteItem = ids =>
  internalApi.post('location/delete-multiple', {
    ids,
  });