let subscribers = {};

const pub = (event:string, data:any) => {
    if (!subscribers[event]) return;
    subscribers[event].forEach(subscriberCallback => {
        subscriberCallback(data);
    });
}

const sub = (event:string,cb:any) => {
    if (!subscribers[event]) {
        subscribers[event] = [];
    }
    subscribers[event].push(cb);
}

const unsub = (event:string,cb:any) => {
    if (!subscribers[event]) return;
    let index = subscribers[event].indexOf(cb);
    subscribers[event].splice(index,1);
}

export { pub, sub, unsub };