import {memo,lazy, useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
// import PrivateRoute from 'components/PrivateRoute';
import App from 'routes/App';
import { useAuth } from 'components/ProvideAuth/hook';
import Login from 'views/public/Login';
import storage from 'shared/utils/storage';

const Register = lazy(() => import('views/public/Register'));
const ResetPassword = lazy(() => import('views/public/ResetPassword'));
const ForgotPassword = lazy(() => import('views/public/ForgotPassword'));
const PageNotFound = lazy(() => import('views/public/PageNotFound'));
// const Home = lazy(() => import('views/protected/Home'));

// import styles from './Root.module.scss';

const Root = props => {
    const auth = useAuth();

    // TBD
    useEffect(()=>{
        if(!auth.user && storage.get('user')){
            auth.loadUserFromSession();
            // navigate('/');
        }
    },[auth.user]);

    useEffect(() => {
        
        // transfers sessionStorage from one tab to another
        var sessionStorage_transfer = function(event) {
            if(!event) { event = window.event; } // ie suq
            if(!event.newValue) return;          // do nothing if no value to work with
            if (event.key == 'getSessionStorage') {
            // another tab asked for the sessionStorage -> send it
            localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
            // the other tab should now have it, so we're done with it.
            localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
            } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
            // another tab sent data <- get it
            var data = JSON.parse(event.newValue);
                for (var key in data) {
                    sessionStorage.setItem(key, data[key]);
                }
            }

            if(!auth.user && storage.get('user')){
                auth.loadUserFromSession();
                // navigate('/');
            }
        };
        
        // listen for changes to localStorage
        if(window.addEventListener) {
            window.addEventListener("storage", sessionStorage_transfer, false);
        }
        
        // Ask other tabs for session storage (this is ONLY to trigger event)
        if (!sessionStorage.length) {
            localStorage.setItem('getSessionStorage', 'init');
            localStorage.removeItem('getSessionStorage');
        };


    },[]);

    if(auth.user){
        return (<App />);
    }else{
        return (
            <>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
            {/* <AppRoutes /> */}
            </>
        );
    
    }

};

export default memo(Root);