import { mapApiKey } from 'shared/utils/common';

class MapService {
    async fetchAddressGeoCode(name,addressLine) {
		return new Promise(async (resolve,reject) => {
			let addressParam = addressLine != '' ? `address=${addressLine}` : `address=''`;
			let url = `https://maps.googleapis.com/maps/api/geocode/json?${addressParam}&key=${mapApiKey}`;
			// console.log(url);
			let response = await fetch(url); 
			let data = await response.json();
			let out = { position: {lat:0,lng:0},address:''};
			if (data && data.results && data.results.length > 0) {
				out = { position:{lat: data.results[0].geometry.location.lat, lng: data.results[0].geometry.location.lng}, address: addressLine, name };
			}
			return resolve(out);	
		});
	}

	placeMarker(Google,latLng,map){
		const marker1 = new Google.maps.Marker({
			position: latLng,
			map: map,
			// icon: image,
			// draggable:true,
		  });
		return marker1;
	}

    placeMarkerAndPanTo(Google,latLng, map) {
        //this.props.google
		const marker1 = new Google.maps.Marker({
		  position: latLng,
		  map: map,
          // icon: image,
          // draggable:true,
		});
		map.panTo(latLng);
        // marker1.setMap(null);
	}

    	// async customAddressData(mapData) {
	// 	const { selectedComboValue } = this.state;
	// 	let fetchedDataArr = new Array(),
	// 		pointsArr = new Array(), parameters = '';
	// 	if (!!mapData) {
	// 		parameters = `${mapData}`;
	// 	}
	// 	if (parameters == '') {
	// 		this.setState({ customCoordinateInfo: [], customCoordinate: [] });
	// 		if (selectedComboValue) {
	// 			this.requestMarkSelectedData(selectedComboValue);
	// 		}
	// 	}
	// 	else {
	// 		let { requestedData, reqCoordinate } = await mapService.fetchAddressGeoCode(parameters.trim());
	// 		let isDatafound = !(Object.keys(requestedData).length === 0 && requestedData.constructor === Object) &&
	// 			!(Object.keys(reqCoordinate).length === 0 && reqCoordinate.constructor === Object)
	// 		if (isDatafound) {
	// 			fetchedDataArr.push(requestedData);
	// 			pointsArr.push(reqCoordinate);
	// 		}
	// 		this.setState({ customCoordinateInfo: fetchedDataArr, customCoordinate: pointsArr });
	// 	}
	// }

	// async requestMarkSelectedData(address) {
	// 	console.log('requestMarkSelectedData-> ',address);
	// 	let fetchedDataArr = new Array(),
	// 		pointsArr = new Array();
	// 	if (address !== '') {
	// 		let { requestedData, reqCoordinate } = await mapService.fetchAddressGeoCode(address.trim());
	// 		// let { requestedData, reqCoordinate } = await this.fetchAddressGeoCode(`550 Battery st, San Francisco, CA, USA`);
	// 		console.log('result coor-> ',requestedData, reqCoordinate );
	// 		let	isDatafound = !(Object.keys(requestedData).length === 0 && requestedData.constructor === Object) &&
	// 				!(Object.keys(reqCoordinate).length === 0 && reqCoordinate.constructor === Object)
	// 		if (isDatafound) {
	// 			fetchedDataArr.push(requestedData);
	// 			pointsArr.push(reqCoordinate);
	// 		}
	// 	}
	// 	this.setState({ comboCoordinateInfo: fetchedDataArr, comboCoordinate: pointsArr });
	// }


}

let mapService = new MapService();
export default mapService;