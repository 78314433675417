import { io } from "socket.io-client";
import internalApi from "shared/utils/internalApi";
let socket = io(internalApi.webSocketUrl(), { 
    // auth: {
    //     token: "abc"
    // },
    autoConnect: false,
    transports: ['websocket'] 
});

const socketClient = () => {
    return socket;
}

export default socketClient;