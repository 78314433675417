export const MAP_SETTINGS = {
	DEFAULT_MAP_OPTIONS: {
	  scrollwheel: true,
	  mapTypeControl: false,
	  fullscreenControl: false,
	  streetViewControl: false,
	  disableDoubleClickZoom: false,
	},
	DEFAULT_CENTER: { lat: 57, lng: 20 },
	DEFAULT_ZOOM: 8,
	MARKER_SIZE: 35,
	PIXEL_OFFSET: {
	  MARKER: {
		X: 0,
		Y: -35,
	  },
	},
	DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
  }