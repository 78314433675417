import internalApi from 'shared/utils/internalApi';

export const fetchItems = ({ search = '', page = 1, limit = 50, individualSearchFields = [] } = {}) => {
    const queryParams = {
        search,
        page,
        limit,
        individualSearchFields
      };
    return internalApi.get('company/list', { params: queryParams })
};

// export const exportCSV = checkedItems => () => {
//     internalApi.get('role/export', {
//         params: { checkedItems: checkedItems.join(',') },
//       }).then(res => {
//         exportCSVFile(res.payload.item, 'Roles');
//       });
//   };

export const getItemById = id => internalApi.get(`company/one/${id}`);
export const saveItem = data => {
    return internalApi.post(
      data.id ? `company/update/${data.id}` : `company/create`,
      data,
    );
  };

export const deleteItem = ids =>
  internalApi.post('company/delete-multiple', {
    ids,
  });

export const getCreditUsageHistory = () => internalApi.get(`company/creditUsage`);
