import {memo, useEffect} from 'react';
// import { useSelector } from 'react-redux';
import { useRootDispatch } from 'shared/store/hook';

//import styled from 'styled-components';
import styles from './Root.module.scss';
import Header from './../Header';
import Sidebar from './../Sidebar';
import SocketBase from 'components/SocketBase';
import {fetchUserPermissions} from 'shared/api/roles';

const Root = ({children}) => {
    const setState = useRootDispatch();

    useEffect(() => {
        fetchUserPermissions().then(out => {
            // console.log('fetchUserPermissions -> ',out.payload.items);
            setState({userPermissions:out.payload.items});
        });
    },[]);

    return (
        <div className={styles.root}>
            <SocketBase>
                <Header />
                <div className={styles.content}>
                    <Sidebar />
                    <div id="rootInnerContent" className={styles.innerContent}>
                        {children}
                    </div>
                </div>
            </SocketBase>
        </div>
    );

};

export default memo(Root);